/* Allgemeine CSS Elemente */

.Hidden
{
    max-height: 0vh;
    visibility: hidden;
}

.glow {
    border: 2px solid #dadada;
    border-radius: 7px;
}

.glow:focus { 
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}
/* CSS Elemente für den Login */

.AdminLogin
{
    text-align: center;
    padding: 22vh 0;
}

.LoginFont1
{
    font-size: 6.6vh;
}

.LoginFont2
{
    font-size: 4.6vh;
}

.FieldDevider
{
    min-height: 2vh;
}

.LoginField
{
    border-radius: 2vh;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(245, 245, 245);
    max-width: 45vh;
    min-height: 28vh;
    text-align: left;
    box-shadow: 0 0 20px #d4aa84;
    padding: 1.3vh 0;

}

.LoginText
{
    font-size: 2vh;
    margin-left: 2vh;
}

.TextInput
{
    border: 1px solid #ccc;
    margin-left: 2vh;
    margin-right: 2vh;
    border-radius: 1vh;
    min-height: 5vh;
    min-width: 40vh;
    font-size: 3vh;
    text-align: center;
    outline: 0;
}

.LoginButton
{

    margin-left: 30.5vh;
    font-size: 3vh;
    margin-right: 2vh;
    min-height: 5vh;
    min-width: 12vh;
    border-radius: 1vh;
    outline: 0;
    background-color: #0088ff;
    border: none;
    box-shadow: 0 0 20px #89caff;
    margin-top: 3vh;
    color: #ffffff;

}

.LoginButton:hover
{

    margin-left: 30.5vh;
    font-size: 3vh;
    margin-right: 2vh;
    min-height: 5vh;
    min-width: 12vh;
    border-radius: 1vh;
    outline: 0;
    background-color: #007deb;
    border: none;
    box-shadow: 0 0 20px #80c5fd;
    margin-top: 3vh;
    color: #ffffff;

}

.LoginButton:active
{

    margin-left: 30.5vh;
    font-size: 3vh;
    margin-right: 2vh;
    min-height: 5vh;
    min-width: 12vh;
    border-radius: 1vh;
    outline: 0;
    background-color: #0072d6;
    border: none;
    box-shadow: 0 0 20px #6fbeff;
    margin-top: 3vh;
    color: #ffffff;

}

/* CSS Elemente für das Steuerelement */

.Navigation
{

}
#bar
{
    float: left;
    overflow: hidden;
    min-height: 100vh;
    min-width: 30vh;
    background-color: #a33232;
    background-image: linear-gradient(#ce9666, #a33232);
    text-align: center;
}
#site
{
    float: left;
    overflow: hidden;
    background-color: #f3f3f3;
    min-width: 85%;
    min-height: 100vh;
}

.NavBarLogo
{
    background: url("../resources/transparentlogo.png")  no-repeat;
    background-size: contain;
    min-height: 25vh;
    background-position: center;
}

.NavBarButton
{
    margin-top: 1vh;
    background-color: #ffffff;
    border: none;
    border-radius: 1vh;
    outline: none;
    min-width: 22vh;
    min-height: 4vh;
    font-size: 2vh;
    opacity: 0.5;
    filter: alpha(opacity=90);
}

.NavBarButton:hover
{
    margin-top: 1vh;
    background-color: #ffffff;
    border: none;
    border-radius: 1vh;
    outline: none;
    min-width: 22vh;
    min-height: 4vh;
    font-size: 2vh;
    opacity: 0.6;
    filter: alpha(opacity=90);
}

.NavBarButton:active
{
    margin-top: 1vh;
    background-color: #ffffff;
    border: none;
    border-radius: 1vh;
    outline: none;
    min-width: 22vh;
    min-height: 4vh;
    font-size: 2vh;
    opacity: 0.7;
    filter: alpha(opacity=90);
}

.OrdersLabel
{
    margin-left: 2vh;
    font-size: 5vh;
}

.Order
{
    min-height: 15vh;
    margin-left: 5vh;
    margin-right: 5vh;
    margin-top: 5vh;

    background-color: #ececec;
    border-radius: 3vh;
    box-shadow: 0 0 1vh 0.5vh #6fbeff;
}

.SmallDiv
{
    min-height: 1vh;
}

.OrderHeadline
{
    margin-top: 2vh;
    margin-left: 2vh;
    font-size: 3vh;
}

.OrderDescription
{
    margin-top: 2vh;
    margin-left: 2vh;
    font-size: 2vh;
    color: #4b4a4a;
}

.Bearbeiten
{
    font-size: 2vh;
    min-height: 5vh;
    min-width: 25vh;
    border-radius: 1vh;
    outline: none;
    float:  right;
    margin-right: 2vh;
    border: none;
    background-color: #ce9666;
}

.Bearbeiten:hover
{
    font-size: 2vh;
    min-height: 5vh;
    min-width: 25vh;
    border-radius: 1vh;
    outline: none;
    float:  right;
    margin-right: 2vh;
    border: none;
    background-color: #c08c5f;
}

.Bearbeiten:active
{
    font-size: 2vh;
    min-height: 5vh;
    min-width: 25vh;
    border-radius: 1vh;
    outline: none;
    float:  right;
    margin-right: 2vh;
    border: none;
    background-color: #b37e4f;
}