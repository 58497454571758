.BetterInput
{
    min-height: 3.4vh;
    font-size: 2.5vh;
    min-width: 40vh;
    border: none;
    box-shadow: 0 0 1.5vh 0.5vh #d1d1d1;

    border-radius: 0.5vh;

    outline: none;
}

.BetterInput:focus
{
    min-height: 3.4vh;
    font-size: 2.5vh;
    border: none;
    box-shadow: 0 0 1.5vh 0.5vh #a5e3fc;

    border-radius: 0.5vh;

    outline: none;
}

.Label
{
    left: 1vh;
    font-size: 2.5vh;
    margin-left: 2vh;
}

.Right
{
    position: relative;

    float: right;

    margin-right: 1vh;
}

.popup
{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}

.popup_inner
{
    border-radius: 0 0 2vh 2vh;
    position: absolute;
    left: 25%;
    right: 25%;
    top: 0%;
    bottom: 25%;
    margin: auto;
    background: white;
}

.paypalHold
{
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
    margin-right: auto;
    margin-left: auto;
    max-width: 50%;
}

.paypalModule
{
    width: 80%;

    margin-right: auto;
    margin-left: auto;
    max-height: 70vh;
}

.infContainer
{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: left;
}

.infInput
{
    float: right;
    width: 65%;
    min-height: 2.4vh;
    font-size: 2.4vh;
}

.infLabel
{
    font-size: 1.3vw;
    margin-left: 5px;
}

.infP
{
    font-size: 2.5vh;
    color: transparent;
    -webkit-user-select: none;     
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#high
{
    color: red;
}
#low
{
    color: transparent;
}

.infH
{
    margin-bottom: 1vh;
}

.bIheader
{
    margin-bottom: 3vh;
    width: 100%;
    height: 7.5vh;
    text-align: left;
}

.cBtn
{
    height: 7.5vh;
    width: 25%;
    border-radius: 0 0 50vh 0;
    border:none;
    background-color: rgb(255, 167, 185);
    color: rgb(75, 49, 54);
    font-size: 3vh;
}

.cBtn:hover
{
    background-color: rgb(245, 159, 176);
    color: rgb(61, 39, 44);
}

.cBtn:active
{
    background-color: rgb(236, 152, 168);
    color: rgb(54, 35, 39);
}

.vBar
{
    margin-top: 3vh;
}

.vItem
{
    height: 12vh;
    width: 30%;
    border-radius: 2vh;
    font-size: 2vh;
    margin-right: 1vh;
    margin-left: 1vh;
    background-color: white;
    border: none;
}
#vSelected
{
    background-color: #f3f3f3;
    box-shadow: 0 0 0 0.3vh rgb(179, 180, 112);
}
#vUnselected
{
    border: 4px dashed #d3d3d3;
    background-color: #f3f3f3;
}

.bIfooter
{
    
    height: 7.5vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 2vh 2vh;
    text-align: right;
}

.nBtn
{
    height: 7.5vh;
    width: 40%;
    border-radius: 7.5vh 0 2vh 0;
    background-color:#a5e3fc;
    color:#233136;
    border: none;
    font-size: 3vh;
}

.nBtn:hover
{
    background-color:#9edaf1;
    color:#1e2a2e;
}

.nBtn:active
{
    background-color:#96d0e7;
    color:#1a2529;
}

.bBtn
{
    height: 7.5vh;
    width: 20%;
    border-radius: 0 0 50vh 0;
    border:none;
    background-color: rgb(255, 217, 167);
    color: rgb(61, 53, 42);
    font-size: 3vh;
}

.bBtn:hover
{
    background-color: rgb(245, 209, 161);
    color: rgb(54, 47, 37);
}

.bBtn:active
{
    background-color: rgb(236, 201, 155);
    color: rgb(32, 28, 22);
}