.m_selBelt
{
    margin-top: 1vh;
    height: 26vh;
}

.m_selsubBelt
{
    float: left;
    width: 50%;
    height: 26vh;
}

.m_selBtn
{
    margin-top: 5%;
    width: 20vh;
    height: 20vh;

    border: none;

    border-radius: 5vh 5vh 5vh 5vh;
}

.m_prevBtn_p
{
    width: 8vh;
    height: 18vh;
    background-color: rgb(190, 163, 126);
    border-radius: 1.5vh;

    margin-left: auto;
    margin-right: auto;
}

.m_prevBtn_l
{
    width: 18vh;
    height: 8vh;
    background-color: rgb(190, 163, 126);
    border-radius: 1.5vh;

    outline: 0;

    margin-left: auto;
    margin-right: auto;
}

.m_selBtn#active
{
    box-shadow: 0 0 0 0.5vh rgb(118, 161, 255);
}

.m_selFinbtn
{
    height: 6vh;
    width: 100%;

    border-radius: 0 0 2vh 2vh;
    border: none;
    font-size: 2.5vh;

    float: right;
}

.m_selFinbtn:active
{
    background-color: rgb(228, 228, 228);
}

.m_tool_1
{
    height: 25vh;
    background-color: rgb(241, 241, 241);
}

.m_tool_2
{
    height: 25vh;

    background-color: rgb(241, 241, 241);
}

.m_tool_3
{
    height: 25vh;

    background-color: rgb(241, 241, 241);
}

.m_tool_footer
{
    background-color:rgb(236, 236, 236);
    height: 2.5vh;
}

.m_tool_toggle
{
    height: 5vh;
    width: 100%;
    font-size: 2.5vh;
    border: none;
    border-radius: 0 0 2vh 2vh;

    background-color:rgb(229, 238, 255);
}

.m_pBox_v
{
    height: 126vh;
    width: 39vh;
    background-color: #cea684;
    box-shadow: 0 0 1.5vh 1vh #e7e7e7; 
    border-radius: 2.5vh;
    margin-top: 2vh;
    margin-bottom: 3vh;
    margin-left: auto;
    margin-right: auto;
}

.uploadPic_m
{
  height: 16vh;
  width: 16vh;
  font-size: 5vh;
  color:rgb(104, 104, 104);

  margin-top: 2.5vh;
  margin-bottom: 2.5vh;

  background: #d8d8d8;
  border: 0.75vh dashed #c0c0c0;
  border-radius: 2vh;
  -webkit-user-select: none;     
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.m_txtBtn_p
{
    margin-top: 2vh;
    width: 70vw;
    height: 7.5vh;
    background-color: rgb(169, 144, 226);
    color: rgb(38, 32, 51);
    box-shadow: 0 0 0 0.5vh rgb(197, 177, 243);
    border: none;
    border-radius: 1.5vh;
    font-size: 3vh;
}

.m_finBtn_p
{
    margin-top: 5vh;
    width: 70vw;
    height: 7.5vh;
    font-size: 3vh;

    border-radius: 7.5vh;

    background-color: rgb(144, 226, 208);
    color: rgb(32, 51, 44);
    box-shadow: 0 0 0 0.5vh rgb(177, 243, 212);
    border: none;
}

.m_beltDelivery
{
    height: 10vh;
}

.m_deliveryChoice
{
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    width: 92.5%;
    height: 9vh;
    font-size: 2.3vh;

    border-radius: 1.5vh;
    border: none;
}
.m_deliveryChoice#bunselected
{
    border: 0.45vh dashed #c0c0c0;
}
.m_deliveryChoice#bselected
{
    box-shadow: 0 0 0 0.5vh rgb(197, 110, 171);
}

.m_buyConBtn
{
    height: 7vh;
    width: 80%;
    margin-top: 1vh;

    border-radius: 7vh;
    border: none;
    font-size: 2.2vh;
    background-color: rgb(160, 255, 207);
    box-shadow: 0 0 0 0.4vh rgb(146, 238, 192);
}