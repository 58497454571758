.Error
{
    text-align: center;
    padding: 35vh 0;
}

.MobileError
{
    text-align: center;
    padding: 40vh 0;
}

.BigText
{
    font-size: 10vh;
}

.NormalText
{
    font-size: 5vh;
}

.HomeButton
{
    border: 2px solid #dadada;
    border-radius: 7px;
    font-size: 4vh;
    min-width: 55vh;
    min-height: 7vh;
    margin-top: 2vh;
    outline: 1;
    background-color: #f2f2f2;
}

.HomeButton:hover
{
    font-size: 4vh;
    min-width: 55vh;
    min-height: 7vh;
    margin-top: 2vh;
    border-radius: 1vh;
    border: none;
    outline: 1;
    background-color: #e6e6e6;
}

.MobileBigText
{
    font-size: 200%;
}

.MobileNormalText
{
    font-size: 150%;
}

.MobileHomeButton
{
    border: 2px solid #dadada;
    border-radius: 7px;
    font-size: 4vh;
    min-width: 42vh;
    min-height: 6.5vh;
    margin-top: 2vh;
    outline: 1;
    background-color: #f2f2f2;
}