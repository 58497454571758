.vWeinbox
{
    float: right;
    margin-right: 5%;
    background: url("../resources/Weinbox.png")  no-repeat;
    background-size: contain;
    height: 25vh;
    width: 20vh;
    background-position: center;
    margin-top: 2.5vh;
    margin-right: 2.5vh;
}

.vWeinbox_m
{
    float: right;
    background: url("../resources/Weinbox.png")  no-repeat;
    background-size: contain;
    height: 25vh;
    width: 20vh;
    background-position: center;
    margin-top: 2.5vh;
    margin-right: 5vh;
}

.pInfo
{
    float: left;
    margin-top: 2.5vh;
    margin-left: 2.5vh;
    height: 25vh;
    width: 25vh;
}

.pPrice
{
    margin-top: 2vh;
    background-color: aquamarine;
    width: 60%;
    height: 5vh;
    border-radius: 2.5vh 5vh 5vh 2.5vh;

    border: none;

    float: left;

    font-size: 2.5vh;
    color: rgb(36, 53, 47);
}

.pbBuy
{
    float: left;
    margin-top: 2.5vh;
    height: 4.5vh;
    width: 80%;
    border-radius: 2.5vh 5vh 5vh 2.5vh;
    border: none;
    font-size: 2vh;
    background-color: #ffa2ff;
    color: #2b1a2b;
}
.pbBuy:hover
{
    background-color: #f398f3;
    color: #221522;
}

.pbBuy:active
{
    background-color: #ec92ec;
    color: #180f18;
}

.pBox
{
    width: 50vh;
    height: 30vh;
    margin-left: auto;
    margin-right: auto;
    background-color: #f8f8f8;
    border-radius: 2vh;
    
    box-shadow: 0 0 0 0.3vh #d1d1d1;
    text-align: center;

}

.pBox_mob
{

    width: 30vh;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    background-color: #f8f8f8;
    border-radius: 2vh;
    
    box-shadow: 0 0 0 0.3vh #d1d1d1;
    text-align: center;

}

.pBoxT
{
    font-size: 3vh;
    color: #3b3b3b;
}

.pBoxST
{
    font-size: 2vh;
    color: #7e7e7e;
}

.ModuleTitle
{
    font-size: 4vh;
}

.MobileModuleTitle
{
    font-size: 4vh;
}

.ptabHold
{
    text-align: center;
}

.ptabButtons
{
    font-size: 2.25vh;
    height: 5vh;
    width:25vh;
    border-radius: 2vh;
    border: none;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-bottom: 5vh;
}
.ptabButtons#this
{
    background-color: #bde6f7;
}
.ptabButtons#other
{
    background-color: #eeeeee;
}

.ptabButtonsMo
{
    font-size: 5vw;
    height: 5vh;
    width: 45vw;
    margin-left: 1vw;
    margin-right: 1vw;
    border: none;
    margin-bottom: 5vh;
    border-radius: 4vw;
}
.ptabButtonsMo#this
{
    background-color: #bde6f7;
}
.ptabButtonsMo#other
{
    background-color: #eeeeee;

}