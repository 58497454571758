.TextAbstand
{
    font-size: 4vh;
}

.Devider
{
    min-height: 5vh;
}

.NextTo
{
    float: left;
}

.ContactBox
{
    background-color: #fafcff;
    min-height: 25vh;
    width: 25.9vh;
    border-radius: 2vh;
}

.ContactDevider
{
    width: 0.2vh;
    background-color: #e0e0e0;
    min-height: 25vh;
}

.HeadlineDiv
{
    height: 3vh;
}

.Information
{
    font-size: 2vh;
}

.ContactHeaderDevider
{
    background-color: #f0f0f0;
    height: 0.2vh;
}


.ContactHeader
{
    font-size: 2.5vh;
}


.ContactBoxHolder
{
    margin-left: auto;
    margin-right: auto;
    min-height: 25vh;
    width: 52vh;
    background-color: #fafcff;
    border-radius: 2vh;
    box-shadow: 0 0 0.8vh 0.5vh #589bff;
}
