.Center
{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.Header
{
    min-height: 20vh;
}
#teil1
{
    background: url("../resources/biglogo.jpeg")  no-repeat;
    background-size: contain;
    min-height: 20vh;
    background-position: center;
    background-color: #d4aa84;
}
#teil2
{
    margin-left: auto;
    margin-right: auto;
    background-color: #d1a47c;
    box-shadow: 0 6px 1vh #b88e69;
}

.HeaderButton
{
    font-size: 2.5vh;
    min-height: 5vh;
    min-width: 15vh;;
    outline: 0;
    border:none;
    background-color: #d4a67e;
}

.HeaderButton:hover
{
    background-color: #e4b78d;
}

.HeaderButton:active
{
    background-color:rgb(206, 159, 119)c;
}

.HeaderTitle
{
    font-size: 6.7vh;
}

.MobileLogo
{
    background: url("../resources/biglogo.jpeg")  no-repeat;
    background-size: contain;
    min-height: 15vh;
    background-position: center;
    background-color: #d4aa84;
}

.MobileSubheader
{
    background-color: #c59a74;
    min-height: 6vh;
    box-shadow: 0 4px 1vh #b88e69;
}

.MobileSubheaderButton
{
    min-height: 6vh;
    width: 33.3%;
    font-size: 2.5vh;
    background-color: #c59a74;
    color: #2e241c;
    outline: none;
    border: none;
}

.MobileSubheaderButton:active
{
    background-color: #c4976d;
}

.dprevBox1
{
    background: url("../resources/prev1.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 25vw;
    background-position: center;
}

.dprevBox2
{
    background: url("../resources/prev2.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 25vw;
    background-position: center;
}

.dprevBox3
{
    background: url("../resources/prev3.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 25vw;
    background-position: center;
}

.dprevBox4
{
    background: url("../resources/prev4.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 25vw;
    background-position: center;
}

.dhomeImageHolder
{
    width: 98vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: auto;
    margin-right: auto;
}

.mimg1
{
    background: url("../resources/prev1.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 100vw;
    background-position: center;
}

.mimg2
{
    background: url("../resources/prev2.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 100vw;
    background-position: center;
}

.mimg3
{
    background: url("../resources/prev3.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 100vw;
    background-position: center;
}

.mimg4
{
    background: url("../resources/prev4.jpg")  no-repeat;
    background-size: contain;
    height: 70vh;
    max-width: 100vw;
    background-position: center;
}