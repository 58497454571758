.ItemÜberschrift
{
  text-align:center;
  font-size: 5vh;
  margin-top: 3vh;
  min-height: 20vh;
}

.PreviewBox_H
{
  margin-left: auto;
  margin-right: auto;
  height: 17.5vw;
  width: 56vw;
  background-color: #cea684;
  box-shadow: 0 0 1.5vh 1vh #e7e7e7; 
  border-radius: 1vh;
}

.PreviewBox_V
{
  margin-left: auto;
  margin-right: auto;
  height: 76vw;
  width: 23.6vw;
  background-color: #cea684;
  box-shadow: 0 0 1.5vh 1vh #e7e7e7; 
  border-radius: 1vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
}


.UploadImageButton
{
  background-color: #a1b9eb;
}

.PreviewBoxMobile
{
  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  height: 87vh;
  width: 29vh;
  background-color: #cea684;
  box-shadow: 0 0 1.5vh 1vh #e7e7e7; 
  
  border-radius: 1vh;
}

.HeaderDevider
{
  min-height: 1vh;
}

.prod_sp
{

}
#f
{
  height: 75vh;
  width: 24.5vw;
  background-color:rgb(228, 228, 228);
  float: left;
}
#s
{
  height: 68.9vh;
  float: right;
  background-color: rgb(255, 255, 255);
  width: 74.5vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.fTopCanv
{
  min-height: 6.1vh;
  background-color: #f0f0f0;
}

.v_menu_tle
{
  background-color: rgb(182, 182, 182);
  min-height: 6vh;
}
#h1_v
{
  font-size: 3vh;
}
#ed1_v
{
  font-size: 1.5vh;
}

.fin_btn
{
  width: 5vh;
  height: 5vh;
  outline: none;
  border: none;
  margin-top: 0.5vh;
  border-radius: 10px;
  background-color: rgb(45, 228, 106);
}

.fin_btn:hover
{
  background-color: rgb(39, 219, 99);
}

.v_menu
{
  
}
#t_view
{
  background-color: rgb(206, 206, 206);
  min-height: 68.3vh;
  width: 6vh;
  float: left;
}
#t_menu
{
  min-height: 68.3vh;
}

.t_view_btn
{
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  width: 5vh;
  height: 5vh;
  outline: none;
  border: none;
  border-radius: 10px;
}
#on
{
  background-color: rgb(169, 185, 206);
}
#off
{
  background-color: rgb(190, 190, 190);
}

.t_2_shape
{
  border-radius: 1vh;
  float: left;
  margin-top: 2vh;
  margin-left: 2.6vh;
  width: 32%;
  height: 14vh;
  background-color:rgb(143, 223, 169);
}

.t_2_btn
{
  margin-top: 1vh;
  font-size: 2vh;
  border: none;
  outline: none;
  border-radius: 0 0 1vh 1vh;
  height: 3.5vh;
  width: 100%;
  background-color: rgb(255, 208, 105);
  color: rgb(97, 75, 26);
}

.t_2_btn:hover
{
  background-color: rgb(250, 202, 100);
}

.t_2_btn:active
{
  background-color: rgb(245, 198, 98);
}

.t_2_dis
{
  min-height: 8.5vh;
  width: 8.5vh;
  margin-top: 1vh;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(143, 223, 169);
}

.t_3_btn
{
  height: 5vh;
  width: 60%;
  font-size: 2vh;
  outline: none;
  border: none;
  background-color: rgb(178, 167, 247);
  color: rgb(58, 52, 95);
  border-radius: 10vh;
  box-shadow: 0 0 1vh 0.5vh rgb(205, 197, 255);
}
.t_3_btn:hover
{
  background-color: rgb(172, 161, 245);
}
.t_3_btn:active
{
  background-color: rgb(169, 157, 241);
}

.t_3_sel
{
  width: 50%;
  min-height: 3vh;
  border: none;
  outline: none;
  border-radius: 1vh 1vh 1vh 1vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.t_4_top
{
  height: 2.5vh;
  border-radius: 1vh 1vh 0 0;
  background-color:rgb(159, 128, 194);
}

.t_4_fon
{
  font-size: 0.9vw;
  color:rgb(57, 40, 75);
}

.t_4_shape
{
  border-radius: 1vh;
  float: left;
  margin-top: 2vh;
  margin-left: 2.6vh;
  width: 32%;
  height: 16.5vh;
  background-color:rgb(180, 143, 223);
}

.t_4_dis
{
  min-height: 8.5vh;
  width: 8.5vh;
  margin-top: 1vh;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(180, 143, 223);
}

.t_4_btn
{
  margin-top: 1vh;
  font-size: 2vh;
  border: none;
  outline: none;
  border-radius: 0 0 1vh 1vh;
  height: 3.5vh;
  width: 100%;
  background-color: rgb(197, 197, 197);
  color: rgb(68, 68, 68);
}
#selected
{
  background-color: rgb(255, 208, 105);
  color: rgb(97, 75, 26);
}
#selected:hover
{
  background-color: rgb(250, 202, 100);
  color: rgb(97, 75, 26);
}
#selected:active
{
  background-color: rgb(245, 198, 98);
  color: rgb(97, 75, 26);
}

.t_4_btn:hover
{
  background-color: rgb(187, 187, 187);
  color: rgb(61, 61, 61);
}


.t_4_btn:active
{
  background-color: rgb(180, 180, 180);
  color: rgb(55, 55, 55);
}

.t_5_shape
{
  border-radius: 1vh;
  float: left;
  margin-top: 2vh;
  margin-left: 2.6vh;
  width: 70%;
  height: 14vh;
  background-color:rgb(223, 182, 143);
}

.t_5_dis
{
  /*
    height: 29vh;
    width: 91vh;
  */
  
  border-radius: 0.75vh;
  min-height: 8.5vh;
  width: 90%;
  width: 30.3;
  height: 9.6vh;
  margin-top: 1vh;
  margin-right: auto;
  margin-left: auto;
  background-color: #cea684;
}

.t_5_btn
{
  margin-top: 1vh;
  font-size: 2vh;
  border: none;
  outline: none;
  border-radius: 0 0 1vh 1vh;
  height: 3.5vh;
  width: 100%;
  background-color: rgb(255, 208, 105);
  color: rgb(97, 75, 26);
}

.t_5_btn:hover
{
  background-color: rgb(250, 202, 100);
}

.t_5_btn:active
{
  background-color: rgb(245, 198, 98);
}

.uploadPic
{
  height: 15vw;
  width: 15vw;
  font-size: 5vw;
  color:rgb(104, 104, 104);

  margin-top: 4vh;

  background: #d8d8d8;
  border: 0.4vw dashed #c0c0c0;
  border-radius: 2vw;
  -webkit-user-select: none;     
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.uploadPic:hover
{
  font-size: 5.5vw;
  color:rgb(104, 104, 104);
  background: #c6d5d6;
  border: 0.4vw dashed #9aa4a5;
}

.fo_h
{
  margin-top: 4vh;
  width: 60%;
  height: 5vh;
  border-radius: 12px 12px 0 0;
  border: none;
  background-color: rgb(105, 202, 170);
  color: rgb(31, 63, 52);
  font-size: 1vw;
}

.fo_q
{
  width: 60%;
  height: 5vh;
  border-radius: 0 0 12px 12px;
  border: none;
  background-color: rgb(129, 255, 213);
  color: rgb(31, 63, 52);
  font-size: 1vw;
}

.fCanv {
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

.cEditor
{
  font-size: 2.5vw;
}

.cModeHold
{
  margin-top: 5vh;
  min-height: 16vw;
  width: 36vw;

  margin-left: auto;
  margin-right: auto;
}

.cCmode
{
  height: 16vw;
  width: 16vw;
  float: left;
  margin-right: 1vw;
  margin-left: 1vw;
}

.grHo
{
  margin-left: auto;
  margin-right: auto;
  width: 4vw;
  height: 10vw;
  background-color: #cea684;
  border-radius: 0.5vw;
}

.grVe
{
  margin-left: auto;
  margin-right: auto;
  width: 10vw;
  height: 4vw;
  background-color: #cea684;
  border-radius: 0.5vw;
}

.cEditorMode
{
  margin-top: 1vw;
  height: 12vw;
  width: 12vw;
  border: none;
  border-radius: 1vw;
  background-color: rgb(241, 241, 241);
}
.cEditorMode#active
{
  box-shadow: 0 0 0 0.5vh #a1b9eb;
}

.cConti
{
  height: 2.5vw;
  width: 20vw;
  font-size: 1.5vw;

  position: absolute;
  right:    1vh;
  bottom:   1vh;

  float: right;
  border-radius: 1vh;
  border: none;
  background-color: rgb(237, 216, 255);
  color: rgb(60, 50, 68);
}